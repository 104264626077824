<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">การโทรนัดเคสก่อนวันนัด</h4>
      </div>
      <v-card-title class="pt-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="4" class="d-flex flex-row align-center">
            <span class="label-drudsandmedical-add-stock-incard mr-3">วันที่</span>
            <v-menu
              ref="menuFilterDate"
              v-model="menuFilterDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedFilterDateFormatted"
                  label="วัน/เดือน/ปี"
                  placeholder="ระบุวัน/เดือน/ปี"
                  append-outer-icon="mdi-calendar-month"
                  readonly
                  required
                  @click:append-outer="menuFilterDate = true"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterData.date"
                no-title
                @change="menuFilterDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="dataListCustomer"
        :loading="loaddata"
        loading-text="กำลังโหลด..."
      >
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-center">
            <v-icon
              :color="item.customer_activity === 'สนใจ' ? 'red' : item.customer_activity === 'ทำ' ? 'pink' : 'purple'"
            >mdi-circle
            </v-icon>
          </td>
          <td class="text-start">{{item.customer_code}}</td>
          <td class="text-start">{{`คุณ${item.customer_fnameth} ${item.customer_lnameth}`}}</td>
          <td class="text-center">{{moment(item.customer_birthdate, "YYYYMMDD").locale('th').fromNow().replace(' ปีที่แล้ว', '')}}</td>
          <td class="text-start">{{`${item.customer_activity} ${item.customer_activity_list}`}}</td>
          <td class="text-end">{{item.customer_prepaid}}</td>
          <td class="text-end">{{item.customer_activity_list_cost - item.customer_prepaid}}</td>
          <td class="text-center">
            <v-btn
              icon
              @click="viewCustomer(item)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogViewCustomer" max-width="700px">
      <v-card>
        <v-card-title>
          ข้อมูลนัดหมาย
        </v-card-title>
        <v-card-text>
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-row align-center pb-0">
              <v-menu
                ref="menuDataDialogDate"
                v-model="menuDataDialogDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedAppointmentDateFormatted"
                    label="วัน/เดือน/ปีที่นัดหมาย*"
                    placeholder="ระบุวัน/เดือน/ปีที่นัดหมาย"
                    append-outer-icon="mdi-calendar-month"
                    readonly
                    required
                    @click:append-outer="menuDataDialogDate = true"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dataDialog.customer_appointment_date"
                  no-title
                  @change="menuDataDialogDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-row align-center pb-0">
              <v-menu
                ref="menuDataDialogTime"
                v-model="menuDataDialogTime"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="dataDialog.customer_appointment_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dataDialog.customer_appointment_time"
                    label="เวลาที่นัดหมาย*"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menuDataDialogTime"
                  v-model="dataDialog.customer_appointment_time"
                  format="24hr"
                  full-width
                  @click:minute="$refs.menuDataDialogTime.save(dataDialog.customer_appointment_time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-row align-center pt-0">
              <span class="label-drudsandmedical-add-stock-incard mr-3">รหัสลูกค้า :</span>
              <span class="label-drudsandmedical-add-stock-incard">{{dataDialog.customer_code}}</span>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-row align-center pt-0">
              <span class="label-drudsandmedical-add-stock-incard mr-3">ชื่อลูกค้า :</span>
              <span class="label-drudsandmedical-add-stock-incard">{{`${dataDialog.customer_fnameth} ${dataDialog.customer_lnameth}`}}</span>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" class="d-flex flex-column align-start">
              <span class="label-drudsandmedical-add-stock-incard mr-3">รายการ :</span>
              <span class="label-drudsandmedical-add-stock-incard">{{`${dataDialog.customer_activity} ${dataDialog.customer_activity_list}`}}</span>
              <span class="label-drudsandmedical-add-stock-incard">{{`มูลค่า ฿${dataDialog.customer_activity_list_cost}`}}</span>
              <span class="label-drudsandmedical-add-stock-incard">{{`จ่ายแล้ว ฿${dataDialog.customer_prepaid}`}}</span>
              <span class="label-drudsandmedical-add-stock-incard">{{`คงเหลือ ฿${dataDialog.customer_activity_list_cost - dataDialog.customer_prepaid}`}}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeViewDialog">
            ปิด
          </v-btn>
          <v-btn color="error" @click="closeViewDialog">
            <v-icon class="mr-3">mdi-close-circle</v-icon>
            โทรไม่ติด
          </v-btn>
          <v-btn
            color="success"
            @click="closeViewDialog"
          >
            <v-icon class="mr-3">mdi-content-save</v-icon>
            ยืนยันนัดหมาย
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      loaddata: false,
      dataDialog: {},
      dataListCustomer: [{
        _id: '0000001',
        customer_code: 'WG640015',
        customer_fnameth: 'ชูใจ',
        customer_lnameth: 'นามสมมติ',
        customer_fnameen: 'Chojai',
        customer_lnameen: 'Namsommud',
        customer_nicknameth: 'กุ๊ก',
        customer_nicknameen: 'Kook',
        customer_birthdate: '1990-01-12',
        customer_activity: 'สนใจ',
        customer_activity_list: 'Botox กราม',
        customer_prepaid: 1000,
        customer_activity_list_cost: 3999
      }, {
        _id: '0000002',
        customer_code: 'WG640020',
        customer_fnameth: 'สมใจ',
        customer_lnameth: 'ใจดี',
        customer_fnameen: 'Somjai',
        customer_lnameen: 'Jaidee',
        customer_nicknameth: 'หญิง',
        customer_nicknameen: 'Ying',
        customer_birthdate: '1960-04-19',
        customer_activity: 'นัดติดตามผล',
        customer_activity_list: 'Filter ร่องแก้ม',
        customer_prepaid: 4999,
        customer_activity_list_cost: 4999
      }, {
        _id: '0000003',
        customer_code: 'WG640035',
        customer_fnameth: 'สมาน',
        customer_lnameth: 'ภักดี',
        customer_fnameen: 'Saman',
        customer_lnameen: 'Phakdee',
        customer_nicknameth: 'สมาน',
        customer_nicknameen: 'Saman',
        customer_birthdate: '1944-07-21',
        customer_activity: 'ทำ',
        customer_activity_list: 'Drip 1 ครั้งที่ 2/5',
        customer_prepaid: 10000,
        customer_activity_list_cost: 10000
      }],
      filterData: {
        date: new Date().toISOString().substr(0, 10)
      },
      menuFilterDate: false,
      dialogViewCustomer: false,
      menuDataDialogDate: false,
      menuDataDialogTime: false
    }
  },
  computed: {
    headers () {
      return [
        {
          text: '',
          align: 'center',
          value: 'customer_activity',
          sortable: false
        }, {
          text: 'รหัส',
          align: 'center',
          value: 'customer_code',
          sortable: false
        }, {
          text: 'ชื่อ - นามสกุล',
          align: 'center',
          value: 'customer_fnameth',
          sortable: false
        }, {
          text: 'อายุ',
          align: 'center',
          value: 'customer_birthdate',
          sortable: false
        }, {
          text: 'รายการ',
          align: 'center',
          value: 'customer_activity',
          sortable: false
        }, {
          text: 'จ่ายแล้ว',
          align: 'center',
          value: 'customer_prepaid',
          sortable: false
        }, {
          text: 'คงเหลือ',
          align: 'center',
          value: 'customer_activity_list_cost',
          sortable: false
        }, {
          text: 'จัดการ',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    },
    computedFilterDateFormatted () {
      const self = this
      return self.filterData.date ? moment(self.filterData.date).format('DD/MM/YYYY') : ''
    },
    computedAppointmentDateFormatted () {
      const self = this
      return self.dataDialog.customer_appointment_date ? moment(self.dataDialog.customer_appointment_date).format('DD/MM/YYYY') : ''
    }
  },
  methods: {
    viewCustomer (item) {
      const self = this
      self.dialogViewCustomer = true
      self.dataDialog = {
        ...item,
        customer_appointment_date: new Date().toISOString().substr(0, 10),
        customer_appointment_time: moment().format('HH:mm')
      }
      // console.log(item)
    },
    closeViewDialog () {
      const self = this
      self.dialogViewCustomer = false
    }
  }
}
</script>

<style scoped>
@import '../../../styles/Dashboard.css';
@import '../../../styles/Customer.css';
</style>
